<template>
  <div>
        <b-row class="col-12 mx-lg-auto">
          <b-col class="col-12 col-md-4 mb-3">
            <strong class="mb-1 d-block">{{$t('twitter.tweet_most_tweeted')}} {{utils.getAvaibleData(this.media.most_retweet_count.performance).performance}} {{$t('twitter.days')}}</strong>
            <a class="border rounded-lg p-1 card-tweet d-flex flex-column justify-content-between"
             :href="`https://twitter.com/${this.user}/status/${mostRetweetCount().basic.id}`" target="_blank">
              <div>
                {{mostRetweetCount().basic.title.substr(0, 100)}}
              </div>
              <div>
                <span class="d-block text-muted col-12 p-0 mt-2 border-bottom pb-1">{{utils.getDatev3(this.mostRetweetCount().basic.time)}}</span>
                <b-row class="text-muted pt-1">
                  <b-col class="reply-color">
                    <b-icon icon="chat-fill" class="heart-filled"/> 
                    {{utils.getFormat(mostRetweetCount().metrics.quote_count)}}
                  </b-col>
                  <b-col class="retweet-color">
                    <feather-icon size="20" :icon="'RepeatIcon'"/> 
                    {{utils.getFormat(mostRetweetCount().metrics.retweet_count)}}
                  </b-col>
                  <b-col class="love-color">
                    <b-icon icon="heart-fill" class="heart-filled"/> 
                    {{utils.getFormat(mostRetweetCount().metrics.favorite_count)}}
                  </b-col>
                  <b-col class="reply-color">
                    <feather-icon size="20" icon="ArrowRightIcon"/> 
                    {{utils.getFormat(mostRetweetCount().metrics.reply_count)}}
                  </b-col>
                </b-row>
              </div>
            </a>
          </b-col>

          <b-col class="col-12 col-md-4 mb-3">
            <strong class="mb-1 d-block">{{$t('twitter.tweet_most_liked')}} {{utils.getAvaibleData(this.media.most_favorite_count.performance).performance}} {{$t('twitter.days')}}</strong>
            <a class="border rounded-lg p-1 card-tweet d-flex flex-column justify-content-between" 
            :href="`https://twitter.com/${this.user}/status/${mostFavoriteCount().basic.id}`" target="_blank">
              <div>
                {{mostFavoriteCount().basic.title.substr(0, 100)}}
              </div>
              <div>
                <span class="d-block text-muted col-12 p-0 mt-2 border-bottom pb-1">{{utils.getDatev3(this.mostFavoriteCount().basic.time)}}</span>
                <b-row class="text-muted pt-1">
                  <b-col class="reply-color">
                    <b-icon icon="chat-fill" class="heart-filled"/> 
                    {{utils.getFormat(mostFavoriteCount().metrics.quote_count)}}
                  </b-col>
                  <b-col class="retweet-color">
                    <feather-icon size="20" :icon="'RepeatIcon'"/> 
                    {{utils.getFormat(mostFavoriteCount().metrics.retweet_count)}}
                  </b-col>
                  <b-col class="love-color">
                    <b-icon icon="heart-fill" class="heart-filled"/> 
                    {{utils.getFormat(mostFavoriteCount().metrics.favorite_count)}}
                  </b-col>
                  <b-col class="reply-color">
                    <feather-icon size="20" icon="ArrowRightIcon"/> 
                    {{utils.getFormat(mostFavoriteCount().metrics.reply_count)}}
                  </b-col>
                </b-row>
              </div>
            </a>
          </b-col>

          <b-col class="col-12 col-md-4 mb-3 ">
            <strong class="mb-1 d-block">{{$t('twitter.tweet_most_replied')}} {{utils.getAvaibleData(this.media.most_reply_count.performance).performance}} {{$t('twitter.days')}}</strong>
            <a class="border rounded-lg p-1 card-tweet d-flex flex-column justify-content-between cursor-pointer"
             :href="`https://twitter.com/${this.user}/status/${mostReplyCount().basic.id}`" target="_blank">
              <div class="col-12">
              {{mostReplyCount().basic.title.substr(0, 100)}}
              </div>
              <div class="col-12">
                <span class="d-block text-muted col-12 p-0 mt-2 border-bottom pb-1">{{utils.getDatev3(this.mostReplyCount().basic.time)}}</span>
                <b-row class="text-muted pt-1">
                  <b-col class="reply-color">
                    <b-icon icon="chat-fill" class="heart-filled"/> 
                    {{utils.getFormat(mostReplyCount().metrics.quote_count)}}
                  </b-col>
                  <b-col class="retweet-color">
                    <feather-icon size="20" :icon="'RepeatIcon'"/> 
                    {{utils.getFormat(mostReplyCount().metrics.retweet_count)}}
                  </b-col>
                  <b-col class="love-color">
                    <b-icon icon="heart-fill" class="heart-filled"/> 
                    {{utils.getFormat(mostReplyCount().metrics.favorite_count)}}
                  </b-col>
                  <b-col class="reply-color">
                    <feather-icon size="20" icon="ArrowRightIcon"/> 
                    {{utils.getFormat(mostReplyCount().metrics.reply_count)}}
                  </b-col>
                </b-row>
              </div>
            </a>
          </b-col>
        </b-row>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
} from "bootstrap-vue";
import utils from '@/libs/utils';

export default {
  components: {
    BRow,
    BCol,
  },
  props: {
    media: {
      required: true
    },
    user: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      utils
    }
  },
  methods: {
    mostRetweetCount() {
      for (const id in this.media.media) {
        
        if (id === utils.getAvaibleData(this.media.most_retweet_count.performance).value[0])
          return this.media.media[id]
      }
    },
    mostFavoriteCount() {
      for (const id in this.media.media) {
        if (id === utils.getAvaibleData(this.media.most_favorite_count.performance).value[0])
          return this.media.media[id]
      }
    },
    mostReplyCount() {
      for (const id in this.media.media) {
        if (id === utils.getAvaibleData(this.media.most_reply_count.performance).value[0])
          return this.media.media[id]
      }
    }
  }
};
</script>
<style>
.reply-color {
  color: #1D9BF0 !important;
}
.retweet-color {
  color: #00BA7C;
}
.love-color {
  color: #F91880;
}
.heart-filled {
  width: 18px;
  height: 18px;
}
.card-tweet {
  min-height: 12em;
  background-color: white;
}
</style>
