<template>
  <div>
    <b-card>
      <b-card-header>
        <b-card-title>{{$t('twitter.content')}}</b-card-title>
      </b-card-header>
      <b-card-body>
        <b-row class="d-flex border-bottom pb-5 flex-wrap">
          <b-col class="col-12 col-md-3 p-0 text-center text-md-left">

            <div class="mb-4" v-for="(item, index) in audienceData" :key="index">
              <div class="col-12 mb-2">
                <strong class="h3">{{item}}</strong>
              </div>
              <div class="col-12">
                <p>
                  <span v-if="item > 0" class="text-success"><feather-icon size="18" :icon="'ArrowUpIcon'" /></span> 
                  <span v-else class="text-danger"><feather-icon size="18" :icon="'ArrowDownIcon'" /></span> 
                  {{$t('twitter.contents_tooltip')}} {{chartOptions.xaxis.categories[index]}} {{$t('twitter.days')}}
                </p>
              </div>
            </div>
          </b-col>
          <b-col class="col-12 col-md-9">

            <apexchart
              type="bar"
              height="350"
              :options="chartOptions"
              :series="series"
              :key="show"
            ></apexchart>
          </b-col>
        </b-row>

        <b-row class="d-flex mt-3">
          <b-col class="col-12 col-md-4 mb-3">
            <div class="col-12 mb-2">
              <strong>{{$t('twitter.tweetLinks')}}</strong>
              <b-icon font-scale="1" icon="question-circle" class="icon-tooltip ml-1" id="tweet_links"/>
              <b-tooltip target="tweet_links" triggers="hover" variant="primary">
                {{$t('twitter.tweet_links_tooltip')}}
              </b-tooltip>
            </div>

            <strong class="h4 font-bolder ml-1">{{utils.getAvaibleData(this.metrics.tweets_with_links_prc.performance).value}} %</strong>
          </b-col>
                    
          <b-col class="col-12 col-md-4 mb-3">
            <div class="col-12 mb-2">
              <strong>{{$t('twitter.prensa')}}</strong>
              <b-icon font-scale="1" icon="question-circle" class="icon-tooltip ml-1" id="prensa"/>
              <b-tooltip target="prensa" triggers="hover" variant="primary">
                {{$t('twitter.tweet_prensa_tooltip')}}
              </b-tooltip>
            </div>

            <strong class="h4 font-bolder ml-1">{{utils.getAvaibleData(this.metrics.tweets_with_media_prc.performance).value}} %</strong>
          </b-col>
          
          <b-col class="col-12 col-md-4 mb-3">
            <div class="col-12 mb-2">
              <strong>{{$t('twitter.quote_ret')}}</strong>
              <b-icon font-scale="1" icon="question-circle" class="icon-tooltip ml-1" id="quote"/>
              <b-tooltip target="quote" triggers="hover" variant="primary">
                {{$t('twitter.tweet_quote_tooltip')}}
              </b-tooltip>
            </div>

            <strong class="h4 font-bolder ml-1">{{utils.getAvaibleData(this.metrics.quoted_retweet_rate.performance).value}} %</strong>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BTooltip,
} from "bootstrap-vue";
import apexchart from "vue-apexcharts";
import utils from '@/libs/utils';

export default {
  components: {
    BTooltip,
    apexchart,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BRow
  },
  props: {
    audienceData: {
      required: true,
    },
    metrics: {
      required: true,
    },
  },
  data() {
    return {
      show: false,
      days: [30, 90, 180],
      utils,
      series: [
        {
          name: "twee",
          data: this.audienceData,
        },
      ],
      chartOptions: {
        tooltip: {
          custom: ({ seriesIndex, dataPointIndex, w}) => {
            return (
              `<div class="width-tooltip-graph">
                  <div class="d-block bg-toottip-graph p-1">
                    ${this.$t('twitter.contents_tooltip')}${this.days[dataPointIndex]} ${this.$t('twitter.days')}.
                  </div>
                  <div class="p-1">
                    <strong class="mr-tooltip-graph">${this.$t('youtube.contents')}: 
                    </strong> ${w.globals.initialSeries[seriesIndex].data[dataPointIndex]}
                  </div>
                </div>`
            )
          }
        },
        colors: ['#7D5AC9'],
        chart: {
          height: 350,
          type: "bar",
          toolbar: {
            show: false
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 200,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: false,
        },

        xaxis: {
          categories: [`30 ${this.$t('twitter.days')}`, `90 ${this.$t('twitter.days')}`, `180 ${this.$t('twitter.days')}`],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
          },
        },
      },
    }
  },
  watch: {
    "$i18n.locale"() {
      this.show = !this.show
      this.chartOptions.xaxis.categories = [`30 ${this.$t('twitter.days')}`, `90 ${this.$t('twitter.days')}`, `180 ${this.$t('twitter.days')}`]
    },
  },
};
</script>
